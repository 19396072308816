import styled from "styled-components";
import { V3ProfileHeroColorVariants } from "../ProfileHero/V3ProfileHero.types";
import { V3Caption } from "../Typography";
import { media, theme } from "../../theme/theme";

const getBorderColor = ($colorVariant: V3ProfileHeroColorVariants, $dashOnHover: boolean) => {
    if ($dashOnHover) {
        return $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255, 255, 255, 0)" : "rgba(110, 118, 135, 0)";
    }

    return $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255, 255, 255, 1)" : "rgba(110, 118, 135, 0.3)";
}

export const EditableContentWrapper = styled.div<{
    $smallPadding: boolean,
    $customBorderRadius: string,
    $colorVariant: V3ProfileHeroColorVariants,
    $dashOnHover: boolean,
    $editMode: boolean,
    $isInline: boolean,
}>`
    border-radius: ${({ $customBorderRadius }) => $customBorderRadius};
    border: 1px dashed ${({ $colorVariant }) => $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255, 255, 255, 1)" : "rgba(110, 118, 135, 0.3)"};
    padding: ${({ $smallPadding }) => $smallPadding ? "4px" : "14px 20px"};
    cursor: pointer;
    display:${({ $isInline }) => $isInline ? "inline-flex" : "block"};
    text-align: center;

    ${V3Caption} {
        color: ${({ $colorVariant, theme }) => $colorVariant === V3ProfileHeroColorVariants.light ? "white" : theme.v3.colors.grey48};
    }

    ${media.custom(theme.breakpoints.phone)} {
        border: 1px dashed ${({ $colorVariant, $dashOnHover }) => getBorderColor($colorVariant, $dashOnHover)};

        &:hover {
            border: 1px dashed ${({ $colorVariant }) => $colorVariant === V3ProfileHeroColorVariants.light ? "rgba(255, 255, 255, 1)" : "rgba(110, 118, 135, 0.3)"};
        }
    }

    > * {
        pointer-events: ${({ $editMode }) => $editMode ? "none" : undefined};
    }
`;