import { youtubeRegex } from "../../types/regex";

/**
 * Iframes inside article must be enhanced with a special wrapper div in order to allow responsive behaviour.
 * This method replaces the html content of a content with the proper dom needed for correct styling on the client.
 * @param html html content as string
 * @returns the dom withe the enchanced iframes
 */
export const enhanceIframesAndBuiltInVideos = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const elems = doc.querySelectorAll("iframe");

    elems.forEach(elem => {
        const wrapper = document.createElement('div');

        wrapper.className = `iframe-wrapper ${!!elem.src.match(youtubeRegex) ? 'iframe-youtube' : 'iframe-instagram'}`;
        wrapper.appendChild(elem.cloneNode());
        elem.replaceWith(wrapper);
    });

    const videos = doc.querySelectorAll("video");

    videos.forEach(elem => {
        elem.setAttribute('controls', 'true');
    });

    const body = doc.documentElement.querySelector('body');

    return body ? body.innerHTML : html;
}