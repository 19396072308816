import { css } from "styled-components";

export const FixedBottomBarMixin = css`
    position: fixed;
    bottom: 0;
    padding: 16px 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px -16px rgba(0, 0, 0, 0.5);
    z-index: 2;
    width: 100%;
    left: 0;
    display: flex;
`;