import { AuthenticatedUserResponse, UserResponse } from "authory-api-types/dist/types";
import { getTwitterProfileComponent } from "../../utils/getTwitterProfileComponent";
import { V3Button } from "../Button";
import { V3IconFacebook, V3IconLinkedin, V3IconTwitter } from "../Icons";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";
import { V3ModalBase } from "../V3ModalBase";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3SubscribedIcons } from "./V3ModalSubscribed.styles";

interface V3ModalSubscribedProps {
    modalOpen: boolean,
    onCloseHandler: () => void,
    author: AuthenticatedUserResponse | UserResponse
}

export const V3ModalSubscribed = ({ modalOpen, onCloseHandler, author }: V3ModalSubscribedProps) => {

    const profileUrl = `${process.env.NEXT_PUBLIC_FRONTEND_URL}/${author?.slug}`;
    const profileUrlWithoutProtocol = profileUrl.replace(/^https:\/\//, "").replace(/^http:\/\//, "");
    const shareText = `Want to stay up-to-date with ${author?.firstName} ${author?.lastName}'s new articles?`;
    const shareSummary = `Check out ${profileUrlWithoutProtocol}`;

    const twitterProfileComponent = getTwitterProfileComponent(author);
    const shareTextTwitter = twitterProfileComponent && twitterProfileComponent.url?.length
        ? `${shareText} ${shareSummary} @${twitterProfileComponent.url.split("/").slice(-1)[0]} via @Authory`
        : `${shareText} ${shareSummary} via @Authory`;


    return <V3ModalBase
        isOpen={modalOpen}
        onClose={onCloseHandler}
        title="Thanks for subscribing to my updates!"
    >
        <VerticalSpacing bottom={24}>
            <V3BodyGrey35>If you like my work, why not tell your friends and colleagues that they can subscribe to receive my updates too? Let them know via:</V3BodyGrey35>
        </VerticalSpacing>
        <V3SubscribedIcons>
            <div
                onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(profileUrl)}`)}
            ><V3IconFacebook width="24" height="24" /></div>
            <div
                onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(profileUrl)}&title=${encodeURIComponent(shareText)}&summary=${encodeURIComponent(shareSummary)}&source=`)}
            ><V3IconLinkedin width="24" height="24" /></div>
            <div
                onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareTextTwitter)}`)}
            ><V3IconTwitter width="24" height="24" /></div>
        </V3SubscribedIcons>
        <VerticalSpacing top={24}>
            <RightAlignPanel>
                <div>
                    <V3Button
                        text="Close"
                        onClick={onCloseHandler}
                    />
                </div>
            </RightAlignPanel>
        </VerticalSpacing>
    </V3ModalBase >
}