import { useState } from "react"
import { theme } from "../../theme/theme"
import { V3IconClose, V3IconMenu } from "../Icons"
import { AboutPageClosePos, AboutPageMenuWrapper, AboutPageMobileMenu, MenuOptionWrapper } from "./AboutPagesMobileMenu.styles"
import { V3ProfileHeroColorVariants } from "./V3ProfileHero.types"
import { HR, MobilePanelH4 } from "../MarketingMenu/MobileMenuPanel.styles"
import { OptionalNextLink } from "../ReusableStyledComponents/OptionalNextLink"
import { VerticalSpacing } from "../VerticalSpacing"
import { EditableItem } from "../EditableSortableItems/EditableSortableItems.types"
import { usePreventScroll } from "@react-aria/overlays"

interface AboutPagesMobileMenuProps {
    colorVariant: V3ProfileHeroColorVariants,
    aboutPages: EditableItem[],
    authorSlug: string,
    customDomain?: string,
}

export const AboutPagesMobileMenu = ({ colorVariant, aboutPages }: AboutPagesMobileMenuProps) => {
    const [menuOpen, setMenuOpen] = useState(false);

    usePreventScroll({
        isDisabled: !menuOpen
    })

    return <AboutPageMobileMenu>
        <V3IconMenu
            onClick={() => setMenuOpen(!menuOpen)}
            fill={colorVariant === V3ProfileHeroColorVariants.dark ? theme.v3.colors.grey48 : theme.v3.colors.white}
        />
        {
            menuOpen && <AboutPageMenuWrapper>
                <AboutPageClosePos>
                    <V3IconClose onClick={() => setMenuOpen(!menuOpen)} />
                </AboutPageClosePos>
                <VerticalSpacing top={55}>
                    <HR />
                    {
                        aboutPages.map((abtPage) => <>
                            <OptionalNextLink
                                href={abtPage.href}
                                useNextRouter={false}
                                useProvidedURLInFul
                            >
                                <MenuOptionWrapper>
                                    <MobilePanelH4>
                                        {abtPage.label}
                                    </MobilePanelH4>
                                </MenuOptionWrapper>
                            </OptionalNextLink >
                            <HR />
                        </>)
                    }
                </VerticalSpacing>
            </AboutPageMenuWrapper>
        }
    </AboutPageMobileMenu >
}