import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { V3Loader } from "../Loader";
import { V3Caption } from "../Typography";
import { V3StickyBottomBarWrapper, V3StickyBottomBarContent, V3StickyBottomBarWrapperBtnPanel } from "./StickyBottomBar.styles";

type StickyBottomBarProps = {
    leftSideLabel: string
    isSubmitting: boolean
    rightSidebuttonConfirmLabel: string,
    onCancelHandler?: () => void,
    onConfirmHandler: () => void,
    maxWidth?: number,
    centeredPanel?: boolean
}

export const StickyBottomBar = ({ leftSideLabel, isSubmitting, rightSidebuttonConfirmLabel, onCancelHandler, onConfirmHandler, maxWidth = 738, centeredPanel = false }: StickyBottomBarProps) => {
    return <V3StickyBottomBarWrapper>
        <div></div>
        <V3StickyBottomBarContent $maxWidth={maxWidth} $centeredPanel={centeredPanel}>
            <div>
                <V3Caption><strong>{leftSideLabel}</strong></V3Caption>
            </div>
            <V3StickyBottomBarWrapperBtnPanel>
                {
                    !isSubmitting ? <>
                        {
                            !!onCancelHandler && <div>
                                <V3Button
                                    text="Cancel"
                                    color={V3ButtonColor.secondary}
                                    onClick={onCancelHandler}
                                    disabled={isSubmitting}
                                />
                            </div>
                        }
                        <div>
                            <V3Button
                                onClick={onConfirmHandler}
                                text={rightSidebuttonConfirmLabel}
                                minWidth={128}
                                disabled={isSubmitting}
                            />
                        </div>
                    </> : <V3Loader height={42} />
                }
            </V3StickyBottomBarWrapperBtnPanel>
        </V3StickyBottomBarContent>
    </V3StickyBottomBarWrapper>
}