import { V3Button } from "../Button";
import { RightAlignPanel } from "../ReusableStyledComponents/RightAlignPanel";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";
import { V3ModalBase } from "../V3ModalBase";
import { VerticalSpacing } from "../VerticalSpacing";

interface V3ModalUnsubscribedProps {
    modalOpen: boolean,
    onCloseHandler: () => void,
}

export const V3ModalUnsubscribed = ({ modalOpen, onCloseHandler }: V3ModalUnsubscribedProps) => {

    return <V3ModalBase
        isOpen={modalOpen}
        onClose={onCloseHandler}
        title="You have unsubscribed successfully"
    >
        <VerticalSpacing bottom={24}>
            <V3BodyGrey35>Have you unsubscribed by mistake? You can re-subscribe on Ruben Freitas's portfolio page anytime.</V3BodyGrey35>
        </VerticalSpacing>
        <VerticalSpacing top={24}>
            <RightAlignPanel>
                <div>
                    <V3Button
                        text="Close"
                        onClick={onCloseHandler}
                    />
                </div>
            </RightAlignPanel>
        </VerticalSpacing>
    </V3ModalBase >
}