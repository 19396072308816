import { AuthenticatedUserResponse, UserResponse } from "authory-api-types/dist/types"
import { PCFRightSide, PCFTextWrapper } from "./ProfileFilters.styles"
import { V3TextInput } from "../TextInput/V3TextInput"
import { V3IconFilter, V3IconMagnifier, V3IconSources } from "../Icons"
import { UserAccountType } from "authory-api-types/dist/enums"
import { EditableUIElement } from "../EditableUIElement"
import { V3ProfileHeroColorVariants } from "../ProfileHero/V3ProfileHero.types"
import { ProfileSearchForm } from "../../pagelayouts/profile/V4ProfilePublicPageLayout"
import { FormikProps } from "formik"
import { FilterOpt } from "../FilterOpt"
import { getSearchFormattedTypesOptions } from "../../utils/getSearchFormattedTypesOptions"
import { getSearchFormattedSourcesOptions } from "../../utils/getSearchFormattedSourcesOptions"
import { V3Select } from "../Select"
import { useWindowSize } from "../../hooks/useWindowSize"
import { theme } from "../../theme/theme"

interface TextSearchProps {
    useExtended: boolean,
    formik: FormikProps<ProfileSearchForm>,
}

const TextSearch = ({ useExtended, formik }: TextSearchProps) => <PCFTextWrapper $extendedMode={useExtended}>
    <V3TextInput
        placeholder={"Search..."}
        isRounded
        icon={<V3IconMagnifier onClick={formik.submitForm} />}
        value={formik.values.text}
        onChange={formik.handleChange}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                formik.submitForm();
            }
        }}
        name="text"
    />
</PCFTextWrapper>

interface ProfileFiltersProps {
    author: AuthenticatedUserResponse | UserResponse,
    editMode: boolean,
    onProfileFilterEditableClick?: () => void,
    formik: FormikProps<ProfileSearchForm>,
    extendedMode?: boolean
}

export const ProfileFilters = ({ author, editMode, formik, onProfileFilterEditableClick, extendedMode = false }: ProfileFiltersProps) => {

    const size = useWindowSize();

    const isMobile = size.width && size.width < theme.breakpoints.phone || false;

    const isBusinessParent = author?.type === UserAccountType.BusinessParent;

    const allowedSourceTypes = getSearchFormattedTypesOptions(author.typeCounts);

    const formattedSourcesOptions = getSearchFormattedSourcesOptions(author, formik.values.collection);

    let formattedSubAccounts = (author.subaccounts || []).map(({ slug, firstName, lastName }) => ({ value: slug, text: `${firstName} ${lastName}` }));
    formattedSubAccounts = [{ value: "", text: "All Accounts" }, ...formattedSubAccounts];

    const sourceFilterOptions = isBusinessParent ? formattedSubAccounts : formattedSourcesOptions;

    if (!author.profileFilterEnabled && !editMode) return <></>;

    const useExtended = extendedMode && !isMobile;

    return <EditableUIElement
        editMode={editMode}
        text="Add search and filters"
        hasContent={author.profileFilterEnabled}
        smallPadding={author.profileFilterEnabled}
        colorVariant={V3ProfileHeroColorVariants.dark}
        isInline={false}
        onClick={onProfileFilterEditableClick}
    >
        <PCFRightSide $extendedMode={useExtended}>
            {
                !useExtended && <TextSearch
                    useExtended={useExtended}
                    formik={formik}
                />
            }
            {
                !!allowedSourceTypes.length && <>
                    {
                        useExtended ? <V3Select
                            name="type"
                            value={formik.values.type}
                            onChange={(e) => {
                                formik.handleChange(e);
                                formik.submitForm();
                            }}
                            options={allowedSourceTypes}
                        /> : <FilterOpt
                            icon={<V3IconFilter />}
                            active={formik.values.type !== ""}
                            name="type"
                            opts={allowedSourceTypes}
                            value={formik.values.type}
                            formik={formik}
                            tooltipContent="Select a type"
                        />
                    }
                </>
            }
            {
                !!sourceFilterOptions.length && <>
                    {
                        useExtended ? <V3Select
                            name={isBusinessParent ? "subAccount" : "source"}
                            value={isBusinessParent ? formik.values.subAccount : formik.values.source}
                            onChange={(e) => {
                                formik.handleChange(e);
                                formik.submitForm();
                            }}
                            options={sourceFilterOptions}
                        /> : <FilterOpt
                            icon={<V3IconSources />}
                            active={formik.values.subAccount !== "" || formik.values.source !== ""}
                            name={isBusinessParent ? "subAccount" : "source"}
                            opts={sourceFilterOptions}
                            value={isBusinessParent ? formik.values.subAccount : formik.values.source}
                            formik={formik}
                            tooltipContent="Select a source"
                        />
                    }
                </>
            }
            {
                useExtended && <TextSearch
                    useExtended={useExtended}
                    formik={formik}
                />
            }
        </PCFRightSide>
    </EditableUIElement>
}