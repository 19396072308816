import styled from "styled-components";

export const V3SubscribedIcons = styled.div`
    display: flex;
    align-items: center;
    max-width: 130px;
    margin: 0 auto;
    justify-content: space-between;
    
    > div {
        cursor: pointer;
    }
`;