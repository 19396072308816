import { CSSProperties, PropsWithChildren } from "react"
import { EditableContentWrapper } from "./EditableUIElement.styles"
import { V3Caption } from "../Typography"
import { V3ProfileHeroColorVariants } from "../ProfileHero/V3ProfileHero.types"

interface EditableUIElementProps extends PropsWithChildren {
    hasContent: boolean,
    text?: string,
    onClick?: () => void,
    smallPadding?: boolean,
    customNoContent?: JSX.Element,
    editMode: boolean,
    customBorderRadius?: string,
    colorVariant: V3ProfileHeroColorVariants,
    isInline?: boolean,
    style?: CSSProperties,
}

export const EditableUIElement = ({
    hasContent = false,
    text,
    children,
    onClick,
    smallPadding = false,
    customNoContent,
    editMode = false,
    customBorderRadius = "100px",
    colorVariant,
    isInline = true,
    style
}: EditableUIElementProps) => !editMode ? <>{children}</> : <EditableContentWrapper
    onClick={onClick}
    $smallPadding={smallPadding}
    $customBorderRadius={customBorderRadius}
    $colorVariant={colorVariant}
    $dashOnHover={editMode && hasContent}
    $editMode={editMode}
    $isInline={isInline}
    style={style}
>
    {hasContent ? children : customNoContent ? customNoContent : <V3Caption><strong>{text}</strong></V3Caption>}
</EditableContentWrapper>