import styled from "styled-components";

export const AboutPageMobileMenu = styled.div`
    position: absolute;
    top: 15px;
    right: 0;
    cursor: pointer;
`;

export const AboutPageClosePos = styled.div`
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
`;

export const AboutPageMenuWrapper = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    overflow-y: scroll;
`;

export const MenuOptionWrapper = styled.div`
    > div {
        padding: 20px 18px;

        a {
            text-decoration: none;
            color: black;

            &:hover {
                color: black;
            }
        }
    }    
`;