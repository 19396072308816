import styled, { css } from "styled-components";
import { V3Body, V3HeadlineH5 } from "../Typography";
import { media, theme } from "../../theme/theme";
import SimpleBar from "simplebar-react";
import { EditableSortableTheme } from "./EditableSortableItems";

export const ECLeftSideCol = styled.div<{ $editMode: boolean, $active: boolean, $isEditingName: boolean, $dragMode?: boolean, $isSettingsIcon?: boolean, $isAddElement?: boolean, $settingsActive?: boolean, $isCheckIcon?: boolean, $colorVariant: EditableSortableTheme, $hasSettingsHidden?: boolean, $alternateHover: boolean }>`
    padding: ${({ $isEditingName, $isSettingsIcon, $isAddElement, $hasSettingsHidden, $alternateHover }) => {
        return $isAddElement && !$isEditingName ? "10px 8px"
            : $isEditingName ? undefined
                : $isSettingsIcon ? "10px 8px 10px 4px"
                    : ($hasSettingsHidden || $alternateHover) ? "10px 8px" : "10px 4px 10px 8px";
    }};
    cursor:  ${({ $isEditingName }) => $isEditingName ? "text" : "pointer"};
    display: flex;
    min-height: 40px;
    width: 100%;
    color: ${({ $colorVariant, $active }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            return !$active ? theme.v3.colors.grey35 : theme.v3.colors.ink;
        } else {
            return !$active ? "rgba(255,255,255,0.7)" : "white";
        }
    }};
    position: relative;
    border-top-right-radius: ${({ $settingsActive }) => $settingsActive ? "6px" : undefined};
    border-bottom-right-radius: ${({ $settingsActive }) => $settingsActive ? "6px" : undefined};
    background: ${({ $colorVariant, $isCheckIcon, $settingsActive, $active, $alternateHover }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            if ($settingsActive || $isCheckIcon || ($active && $alternateHover)) return "rgba(46, 119, 255, 0.10)";
        } else {
            if ($settingsActive || $isCheckIcon || ($active && $alternateHover)) return "rgba(255, 255, 255, 0.10)";
        }
    }};
    border-radius:  ${({ $alternateHover }) => $alternateHover ? "4px" : undefined};

    ${V3Body} {
        color: ${({ $isAddElement, $colorVariant }) => {
        if ($isAddElement) return $colorVariant === EditableSortableTheme.Dark ? undefined : "rgba(255,255,255, 0.7)";
    }}
    }

    svg {
        path {
            stroke: ${({ $colorVariant, $settingsActive }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            return $settingsActive ? theme.v3.colors.blue : undefined;
        } else {
            return $settingsActive ? "white" : "rgba(255, 255, 255, 0.7)";
        }
    }};
        }
    }

    &:first-child {
        padding-left: ${({ $editMode, $dragMode, $alternateHover }) => !$editMode && !$dragMode && !$alternateHover ? "0px" : undefined};
    }

    &:hover {
        background: ${({ $colorVariant, $alternateHover }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            if ($alternateHover) return "rgba(46, 119, 255, 0.10)";
        } else {
            if ($alternateHover) return "rgba(255, 255, 255, 0.10)";
        }
    }};
        &:after {
            display: ${({ $editMode, $alternateHover }) => !$editMode && !$alternateHover ? "block" : undefined};
        }

        ${V3Body} {
            color: ${({ $isAddElement, $colorVariant }) => {
        if ($isAddElement) return $colorVariant === EditableSortableTheme.Dark ? undefined : "rgba(255,255,255, 1)";
    }}
        }
    }

    &:after {
        content: "";
        width: ${({ $editMode, $hasSettingsHidden }) => $editMode ? $hasSettingsHidden ? "calc(100% - 16px)" : "calc(100% - 12px)" : $hasSettingsHidden ? "calc(100% - 8px)" : "calc(100% - 4px)"};
        display: ${({ $isEditingName, $active, $dragMode, $alternateHover }) => !$active || $dragMode || $isEditingName || $alternateHover ? "none" : "block"};
        height: 3px;
        bottom: -2px;
        left: ${({ $editMode }) => $editMode ? "8px" : "0"};
        position: absolute;
        background: ${({ $colorVariant }) => {
        return $colorVariant === EditableSortableTheme.Dark ? "rgb(46, 119, 255)" : "white";
    }}
    }

    &:hover {
        background: ${({ $colorVariant, $editMode, $isEditingName }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            return $editMode && !$isEditingName ? "rgba(46, 119, 255, 0.10)" : undefined;
        } else {
            return $editMode && !$isEditingName ? "rgba(255, 255, 255, 0.10)" : undefined;
        }
    }};
        color: ${({ $colorVariant, theme, $editMode }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            return !$editMode ? theme.v3.colors.ink : theme.v3.colors.blue;
        } else {
            return "white";
        }
    }};

        svg {
            path {
                stroke: ${({ $colorVariant, theme }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            return theme ? theme.v3.colors.blue : null;
        } else {
            return "white";
        }
    }};
            }
        }
    }
`;

export const EditableItemWrapper = styled(V3HeadlineH5) <{
    $editMode: boolean,
    $isAddElement: boolean,
    $isOverlay: boolean,
    $dragMode: boolean,
    $activeDrag: boolean,
    $isEditingName: boolean,
    $orientation?: "before" | "after",
    $colorVariant: EditableSortableTheme,
    $alternateHover: boolean
}>`
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    background: ${({ $isOverlay, $colorVariant }) => $isOverlay ? $colorVariant === EditableSortableTheme.Dark ? "white" : undefined : undefined};
    margin-right: ${({ $editMode, $alternateHover }) => $editMode ? "0px" : $alternateHover ? "10px" : "20px"};
    min-width: ${({ $isAddElement }) => $isAddElement ? "176px" : undefined};
    pointer-events: ${({ $dragMode }) => $dragMode ? "none" : undefined};
    border: ${({ $colorVariant, $isOverlay, $editMode, $dragMode, $isEditingName, $orientation }) => {
        if (!!$orientation) return $colorVariant === EditableSortableTheme.Dark ? "1px dashed #2E77FF" : "1px solid white";
        if ($isOverlay || $dragMode || $isEditingName) return $colorVariant === EditableSortableTheme.Dark ? "1px dashed rgba(171, 176, 186, 0.50)" : "1px dashed rgba(255, 255, 255, 0.50)";
        return $editMode ? "1px dashed rgba(110, 118, 135, 0.3)" : undefined;
    }};

    ${media.custom(theme.breakpoints.phone)} {
        border: ${({ $isOverlay, $editMode, $dragMode, $isEditingName, $orientation, $colorVariant }) => {
        if (!!$orientation) return $colorVariant === EditableSortableTheme.Dark ? "1px dashed #2E77FF" : "1px solid white";
        if ($isOverlay || $dragMode || $isEditingName) return $colorVariant === EditableSortableTheme.Dark ? "1px dashed rgba(171, 176, 186, 0.50)" : "1px dashed rgba(255, 255, 255, 0.50)";
        return $editMode ? $colorVariant === EditableSortableTheme.Dark ? "1px dashed rgba(171, 176, 186, 0)" : "1px dashed rgba(255, 255, 255, 0)" : undefined;
    }};
    }

    > * {
        opacity: ${({ $activeDrag }) => $activeDrag ? "0" : undefined};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    [contenteditable="true"] {
        outline: none;
        padding: 10px 8px;

        &:empty:before {
            content: attr(placeholder);
            display: block;
            color: #aaa;
        }
    }
`;

const HoverMixin = css<{ $colorVariant: EditableSortableTheme, $editMode: boolean, $editingComponentID?: string }>`
    ${media.custom(theme.breakpoints.phone)} {
        border: ${({ $colorVariant, $editingComponentID, $editMode }) => {
        if ($colorVariant === EditableSortableTheme.Dark) {
            return !!$editingComponentID?.length ? undefined : $editMode ? "1px dashed rgba(171, 176, 186, 0.50)" : undefined
        } else {
            return !!$editingComponentID?.length ? undefined : $editMode ? "1px dashed rgba(255, 255, 255, 0.50)" : undefined;
        }
    }};
    }
`;

export const HomeWrapper = styled.div<{ $colorVariant: EditableSortableTheme, $editMode: boolean, $editingComponentID?: string }>`
    border: 1px solid rgba(0,0,0,0);
    border-radius: 6px;

    &:hover {
        ${HoverMixin};
    }
`;

export const EditableSortableItemsWrapper = styled.div<{ $editMode: boolean, $dragMode: boolean, $editingComponentID?: string, $isDropdownOpen?: boolean, $colorVariant: EditableSortableTheme, $alternateHover: boolean }>`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom:  ${({ $alternateHover }) => !$alternateHover ? "12px" : undefined};
    position: relative;
    min-height: ${({ $dragMode }) => $dragMode ? "42px" : undefined};

    ${EditableItemWrapper} {
        flex-shrink: 0;
        border-radius: 6px;

        ${media.custom(theme.breakpoints.phone)} {
           border: ${({ $isDropdownOpen }) => $isDropdownOpen ? "1px dashed rgba(171, 176, 186, 0.50)" : undefined};
        }

        &:not(:last-child) {
            border-right: 1px dashed rgba(171, 176, 186, 0);
        }
    }

    &:hover {
        ${EditableItemWrapper} {
            ${HoverMixin};
        }

        ${ECLeftSideCol} {
            &:after {
                display: ${({ $editMode }) => $editMode ? "none" : undefined};
            }
        }
    }
`;

export const AddColShortcutPos = styled.div<{ $colorVariant: EditableSortableTheme, $scrollCustomColor?: string }>`
    position: absolute;
    right: 0;
    z-index: 2;
    top: 0;
    width: 32px;
    height: 42px;
    background:  ${({ $colorVariant, $scrollCustomColor }) => {
        if ($scrollCustomColor) return $scrollCustomColor;
        if ($colorVariant === EditableSortableTheme.Dark) {
            return "white";
        } else {
            return "rgba(8,22,43,0.6)";
        }
    }};
    border: 1px dashed rgba(171, 176, 186, 0.50);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;

    &:before {
        pointer-events: none;
        content: '';
        position: absolute;
        background-image: linear-gradient(to right, transparent, ${({ $colorVariant, $scrollCustomColor }) => {
        if ($scrollCustomColor) return $scrollCustomColor;
        if ($colorVariant === EditableSortableTheme.Dark) return "white";
        if ($colorVariant === EditableSortableTheme.Light) return "rgba(8,22,43,0.6)";
    }});
        opacity: 2.5; 
        height: 100%;
        transform: translateX(-100%);
        width: 25px;
        left: -1px;
        top: 0;
        z-index: 1;
    }
`;

export const AddItShortcutBackground = styled.div<{ $active?: boolean, $colorVariant: EditableSortableTheme }>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ $active }) => $active ? "rgba(46, 119, 255, 0.10)" : undefined};

    &:hover {
        background: ${({ $colorVariant }) => {
        return $colorVariant === EditableSortableTheme.Dark ? "rgba(46, 119, 255, 0.10)" : "rgba(255,255,255, 0.3)";
    }};

        svg {
            polyline {
                stroke:  ${({ $colorVariant }) => $colorVariant === EditableSortableTheme.Dark ? theme.v3.colors.blue : "white"};
            }
        }
    }
`;

export const ECWrapper = styled.div`
    position: relative;
    overflow: hidden;
`

export const SimpleBarWithFade = styled(SimpleBar) <{ $colorVariant: EditableSortableTheme, $scrollCustomColor?: string }>`
    &.left-shadow {
        &:before {
            pointer-events: none;
            content: '';
            position: absolute;
            background-image: linear-gradient(to left, transparent, ${({ $colorVariant, $scrollCustomColor }) => {
        if ($scrollCustomColor) return $scrollCustomColor;
        if ($colorVariant === EditableSortableTheme.Dark) return "white";
        if ($colorVariant === EditableSortableTheme.Light) return "rgba(8,22,43,0.6)";
    }});
            opacity: 1; 
            height: calc(100% - 10px);
            width: 30px;
            left: 0;
            top: 0;
            z-index: 1;
        }
    }

    &.right-shadow {
        &:after {
            pointer-events: none;
            content: '';
            position: absolute;
            background-image: linear-gradient(to right, transparent, ${({ $colorVariant, $scrollCustomColor }) => {
        if ($scrollCustomColor) return $scrollCustomColor;
        if ($colorVariant === EditableSortableTheme.Dark) return "white";
        if ($colorVariant === EditableSortableTheme.Light) return "rgba(8,22,43,0.6)";
    }});
            opacity: 1; 
            height: calc(100% - 10px);
            width: 35px;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }
`;

export const ReorderWrapper = styled.div<{ $colorVariant: EditableSortableTheme }>`
    display: flex;

    > div {
        &:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 8px;
            height: 42px;
            border-radius: 6px;
            cursor: pointer;
            border: ${({ $colorVariant }) => $colorVariant === EditableSortableTheme.Dark ? "1px dashed rgba(171, 176, 186, 0.50)" : "1px dashed rgba(255,255,255, 0.5)"};

            &:hover {
                background: ${({ $colorVariant }) => $colorVariant === EditableSortableTheme.Dark ? "rgba(46, 119, 255, 0.10)" : "rgba(255,255,255, 0.3)"};

                svg {
                    polyline {
                        stroke:  ${({ $colorVariant }) => $colorVariant === EditableSortableTheme.Dark ? theme.v3.colors.blue : "white"};
                    }
                }
            }
        }
    }
`;

export const ScrollShortcutPos = styled.div<{ $colorVariant: EditableSortableTheme }>`
    position: absolute;
    right: 0;
    z-index: 2;
    top: 7px;
    width: 24px;
    height: 24px;
    background: ${({ $colorVariant }) => {
        if ($colorVariant === EditableSortableTheme.Dark) return "white";
        if ($colorVariant === EditableSortableTheme.Light) return "rgba(8,22,43,1)";
    }};

    svg {
        opacity: 0.15;
        position: relative;
        left: 5px;
    }

    &:before {
        pointer-events: none;
        content: '';
        position: absolute;
        background-image: linear-gradient(to right, transparent, ${({ $colorVariant }) => {
        if ($colorVariant === EditableSortableTheme.Dark) return "white";
        if ($colorVariant === EditableSortableTheme.Light) return "rgba(8,22,43,0.6)";
    }});
        opacity: 2.5; 
        height: 100%;
        transform: translateX(-100%);
        width: 25px;
        left: 4px;
        top: 0;
        z-index: 1;
    }
`;