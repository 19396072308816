import styled from "styled-components";
import { media, theme } from "../../theme/theme";
import { FixedBottomBarMixin } from "../ReusableStyledComponents/FixedBottomMixin";

export const V3StickyBottomBarWrapper = styled.div`
    ${FixedBottomBarMixin}
`;

export const V3StickyBottomBarContent = styled.div<{ $maxWidth: number, $centeredPanel: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${({ $maxWidth }) => `${$maxWidth}px`};
    margin: 0 auto;
    width: 100%;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding: ${({ $centeredPanel }) => $centeredPanel ? "0 20px" : undefined};
    }
`;

export const V3StickyBottomBarWrapperBtnPanel = styled.div`
    display: flex;
    align-items: center;

    > div {
        &:first-child {
            margin-right: 14px;
        }
    }
`;