import styled, { css } from "styled-components";
import { media } from "../../theme/theme";

const BlockQuoteCSS = css`
    display: block;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 40px !important;
    margin-inline-end: 40px !important;
    border-left: ${({ theme }) => theme ? `2px solid ${theme.colors.dodgerBlue}` : null} !important;
    margin-left: 10px!important;
    padding-left: 20px!important;
    font-size: 20px!important;
    line-height: 30px!important;
    font-family: "Lora", serif!important;
    color: #394555!important;
    margin-top: 36px!important;
    margin-bottom: 36px!important;
`;

export const V3ArticleView = styled.article`
    line-height: 1.5;
    word-break: break-word;
    table {
        width: 100%;
        border-collapse: collapse;
    }

    table td {
        border: 1px solid black;
        padding: 5px;
        height: 25px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #08162b!important;
        display: block!important;
        margin-block-start: 0.83em!important;
        margin-block-end: 0.83em!important;
        font-family: ${({ theme }) => theme ? `${theme.typography.type.assistant}px` : null}!important;

        &:first-child {
            margin-top: 0!important;
        }

        b, strong {
            font-weight: normal!important;
        }

        em {
            font-style: initial!important;
        }
    }

    b, strong, em {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: normal!important;
            font-style: initial!important;
        }
    }

    h1 {
        font-size: 24px!important;
        line-height: 32px!important;

        ${media.phone} {
            font-size: 36px!important;
            line-height: 1.3em!important;
        }

        @media print {
            page-break-inside: avoid;

            &:after {
                content: "";
                display: block;
                height: 100px;
                margin-bottom: -100px;
            }
        }
    }

    h2 {
        font-size: 22px!important;
        line-height: 28px!important;
        margin-top: 48px!important;
        margin-bottom: 12px!important;

        ${media.phone} {
            font-size: 28px!important;
            line-height: 36px!important;
        }

        @media print {
            page-break-inside: avoid;

            &:after {
                content: "";
                display: block;
                height: 100px;
                margin-bottom: -100px;
            }
        }
    }

    h3,h4,h5,h6 {
        font-size: 24px!important;
        line-height: 30px!important;
        margin-top: 48px!important;
        margin-bottom: 12px!important;

        @media print {
            page-break-inside: avoid;

            &:after {
                content: "";
                display: block;
                height: 100px;
                margin-bottom: -100px;
            }
        }
    }

    blockquote {
       ${BlockQuoteCSS}
    }

    div, p, ol, ul, li, table {
        font-size: 17px!important;
        line-height: 27px!important;
        font-family: "Lora", serif!important;
        color: #394555!important;

        ${media.phone} {
            font-size: 19px!important;
            line-height: 31px!important;
        }

        @media print {
            font-size: 16px!important;
            line-height: 30px!important;
        }
    }

    a {
        color: #2E77FF!important;
        text-decoration: none!important;
    }

    a:hover {
        color: #0050EE!important;
    }

    p + p {
        margin-top: 1.625rem!important;
    }

    img {
        max-width: 100%!important;
    }

    figure {
        text-align: center!important;
        margin: 1em auto!important;
    }

    video {
        max-width: 100%;
    }

    @media screen and (max-width: 600px) {
        /* Removes image margin left/right on mobile devices */
        figure {
            margin-left: 0px!important;
            margin-right: 0px!important;
        }
    }

    figcaption {
        color: #394555!important;
        font-size: 16px!important;
        text-align: center!important;
        font-family: "Times New Roman"!important;
    }

    li {
        color: #394555!important;

        p {
            display: inline-block;
        }
    }

    b, strong {
        font-weight: bolder;
    }

    em {
        font-style: italic;
    }

    pre,
    code {
        margin-top: 1.625rem!important;
        margin-bottom: 1.625rem!important;
        font-family: monospace, monospace!important;
        font-size: 16px!important;
        line-height: 24px!important;
        padding: 0!important;
        background: none!important;
        font-size: 16px!important;
        color: black!important;
        max-width: 100%;
        overflow: scroll;
    }

    ol, ul {
        display: block;
        margin-block-start: 1em!important;
        margin-block-end: 1em!important;
        margin-inline-start: 0px!important;
        margin-inline-end: 0px!important;
        padding-left: 1.5em!important;
    }

    ol li:not(.ql-direction-rtl),
    ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
    }

    ol li:not(.ql-direction-rtl),
    ul li:not(.ql-direction-rtl) {
        padding-left: 1.5em;
    }

    ol li.ql-direction-rtl,
    ul li.ql-direction-rtl {
        padding-right: 1.5em;
    }
    ol li {
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-0;

        p {
            display: inline;
        }
    }
    ol li:before {
        content: counter(list-0, decimal) '. ';
    }
    ol li.ql-indent-1 {
        counter-increment: list-1;
    }
    ol li.ql-indent-1:before {
        content: counter(list-1, lower-alpha) '. ';
    }
    ol li.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-2 {
        counter-increment: list-2;
    }
    ol li.ql-indent-2:before {
        content: counter(list-2, lower-roman) '. ';
    }
    ol li.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
    ol li.ql-indent-3 {
        counter-increment: list-3;
    }
    ol li.ql-indent-3:before {
        content: counter(list-3, decimal) '. ';
    }
    ol li.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    }

    li.ql-indent-1:not(.ql-direction-rtl) {
        padding-left: 3em;
    }

    li.ql-indent-2:not(.ql-direction-rtl) {
        padding-left: 4.5em;
    }

    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }

    li::before {
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }

    ul li:before {
        content: "•";
    }

    ul li p {
        display: inline;
    }

    iframe {
        opacity: 0;
        transition-delay: 150ms;
    }

    .iframe-wrapper {
        margin: 20px auto;
        position: relative;
        overflow: hidden;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }

    .iframe-youtube {
        padding-top: 56.25%;
    }

    .iframe-instagram {
        padding-top: 83%    
    }
`;