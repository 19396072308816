import { Collection } from "authory-api-types/dist/types";
import { debounce } from "lodash";
import { useState } from "react";
import { V3TertiaryButton } from "../Button";
import { DropdownOptionCTA } from "../DropdownOptionCTA";
import { V3IconCollections, V3IconMagnifier } from "../Icons";
import { V3BlueRoundPlus } from "../ReusableStyledComponents/V3BlueRoundPlus";
import { V3TextInput } from "../TextInput/V3TextInput";
import { ATCPCreatecolBtn, ATCPSearchWrapper, ATCPWrapper, CollScrollWrapper } from "./AddToCollectionDropdownPanel.styles";
import { decodeEntities } from "../../utils/decodeHTMLEntities";

interface AddToCollectionDropdownPanelProps {
    collections: Collection[],
    onCreateCollectionClick?: () => void,
    onCollectionClick: (slug: string) => void,
}

export const AddToCollectionDropdownPanel = ({ collections, onCreateCollectionClick, onCollectionClick }: AddToCollectionDropdownPanelProps) => {
    const [modalSearchTerm, setModalSearchTerm] = useState("");
    const debouncedSetSearch = debounce(setModalSearchTerm, 250);

    return <ATCPWrapper>
        {
            collections.length > 4 && <ATCPSearchWrapper>
                <V3TextInput
                    placeholder="Search collections..."
                    isRounded
                    onChange={(e) => debouncedSetSearch(e.target.value)}
                    icon={<V3IconMagnifier />}
                />
            </ATCPSearchWrapper>
        }
        <CollScrollWrapper forceVisible="y" autoHide={false}  >
            {
                collections
                    .filter(col => col.label?.toLowerCase().includes(modalSearchTerm.toLowerCase()))
                    .filter(col => !!col.label)
                    .map(col => <DropdownOptionCTA
                        icon={<V3IconCollections width="16" height="16" />}
                        label={decodeEntities(col.label)}
                        key={col.slug}
                        onClick={() => onCollectionClick(col.canonicalSlug)}
                    />)
            }
        </CollScrollWrapper>
        {
            !!onCreateCollectionClick && <ATCPCreatecolBtn onClick={() => onCreateCollectionClick && onCreateCollectionClick()}>
                <V3TertiaryButton
                    icon={<V3BlueRoundPlus />}
                    text="Create new collection"
                />
            </ATCPCreatecolBtn>
        }
    </ATCPWrapper >
}