import styled from "styled-components";
import { media, theme } from "../../theme/theme";
import SimpleBar from 'simplebar-react';

export const ATCPWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-width: 150px;
`;

export const CollScrollWrapper = styled(SimpleBar).attrs<{ $boxShadow?: string }>(
    ({ $boxShadow }) => ({
        style: {
            boxShadow: $boxShadow ? $boxShadow : undefined
        },
    })
)`
    max-height: 170px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        max-height: 168px;
    }
`;

export const ATCPCreatecolBtn = styled.div`
    padding:  10px 0;
    height: 34px;
    width: 100%;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        padding:  10px 11px;
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;
       
        ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
            background: ${({ theme }) => theme ? theme.v3.colors.grey96 : null};
            border-radius: 6px;
        }
    }
`;

export const ATCPSearchWrapper = styled.div`
    margin-top: 14px;
    margin-bottom: 14px;
    width: 100%;

    ${media.custom(theme.v3.layout.sidebarLayout.mobile)} {
        margin-top: 0;
    }
`;