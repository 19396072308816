import { PrivateAboutPagesResponse, PublicAboutPageResponse } from "authory-api-types/dist/types"
import { FakeAboutPagePlaceholder, V4AboutPageContentModeWrapper, V4AboutPageWrapper } from "./V3AboutPageContent.styles"
import { V3Loader } from "../Loader"
import { V3ArticleView } from "../ReusableStyledComponents/V3ArticleView"
import { V3Dropdown } from "../Dropdown"
import { DropdownOptionCTA } from "../DropdownOptionCTA"
import { V3IconEdit } from "../Icons"
import { PropsWithChildren, useContext, useEffect, useState } from "react"
import dynamic from "next/dynamic"
import { StickyBottomBar } from "../StickyBottomBar"
import { VerticalSpacing } from "../VerticalSpacing"
import { useWarnIfUnsavedChanges } from "../../hooks/confirmNavigation"
import { SetFooterVisible } from "../../layout/SideBarLayout"
import { enhanceIframesAndBuiltInVideos } from "../ContentRenderer/utils"

const QuillEditor = dynamic(
    () => import('../EditArticle/quill-editor/QuillEditor'),
    { ssr: false }
);

interface ContentDropdownContainerProps extends PropsWithChildren {
    isPrivateView: boolean,
    setIsEditing: (isEditing: boolean) => void
}

const ContentDropdownContainer = ({ isPrivateView, children, setIsEditing }: ContentDropdownContainerProps) => {

    if (!isPrivateView) return <>{children}</>;

    return <V3Dropdown
        clientPointEnabled
        title="Content Settings"
        renderReferenceElement={() => {
            return <V4AboutPageContentModeWrapper $isPrivateView>{children}</V4AboutPageContentModeWrapper>;
        }}
        renderPopperElement={(_, setActive) => {
            return <>
                <DropdownOptionCTA
                    label={"Edit"}
                    icon={<V3IconEdit />}
                    onClick={() => {
                        setIsEditing(true);
                        setActive(false);
                    }}
                />
            </>;
        }}
    />
}

interface V4AboutPageContentProps {
    userAboutPage?: PublicAboutPageResponse | PrivateAboutPagesResponse,
    isPrivateView: boolean,
    onTemporaryImageUploadHandler?: (file: File) => Promise<string>,
    onAboutPageSubmitContent?: (html: string, id: string) => Promise<void>,
    queryArgs: Record<string, string>,
}

export const V4AboutPageContent = ({ userAboutPage, isPrivateView, queryArgs, onTemporaryImageUploadHandler, onAboutPageSubmitContent }: V4AboutPageContentProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [aboutQuillPageContent, setAboutPageQuillContent] = useState(userAboutPage?.html || "");
    const [updateSubsmitting, setUpdateSubmitting] = useState(false);
    const [quillUpdated, setQuillUpdated] = useState(false);
    const [aboutPageId, setAboutPageId] = useState(userAboutPage && 'id' in userAboutPage ? userAboutPage.id : 0);
    const [renderedContent, setRenderedContent] = useState("");

    useEffect(() => {
        if (!userAboutPage) return;

        // for public or private - always update rendered content
        setRenderedContent(enhanceIframesAndBuiltInVideos(userAboutPage.html || ""));

        // If in private view
        if (userAboutPage && 'id' in userAboutPage) {
            // if id changed
            if (aboutPageId !== userAboutPage.id) {
                setAboutPageQuillContent(userAboutPage?.html || "");
                setQuillUpdated(false);
                setIsEditing(false);
                setAboutPageId(userAboutPage.id);
            }
        }
    }, [userAboutPage]);

    useEffect(() => {
        if (!userAboutPage) return;

        setRenderedContent(enhanceIframesAndBuiltInVideos(userAboutPage.html || ""));
    }, []);

    useWarnIfUnsavedChanges(quillUpdated);

    const setFooterVisible = useContext(SetFooterVisible);
    setFooterVisible(true);

    const onAboutPageSave = async () => {
        setUpdateSubmitting(true);

        try {
            if (onAboutPageSubmitContent) {
                await onAboutPageSubmitContent(aboutQuillPageContent, queryArgs.aboutPage as string);
            }
            setUpdateSubmitting(false);
            setQuillUpdated(false);
            setIsEditing(false);
        } catch {
            setUpdateSubmitting(false);
        }
    }

    return <>
        <V4AboutPageWrapper>
            {
                (!userAboutPage) ? <V3Loader /> : <>
                    <>
                        {
                            isEditing ? <>
                                <VerticalSpacing bottom={80}>
                                    <QuillEditor
                                        placeholder={"Type something here"}
                                        content={aboutQuillPageContent || ""}
                                        onChange={(value: string) => {
                                            setQuillUpdated(true);
                                            setAboutPageQuillContent(value);
                                        }}
                                        onTemporaryImageUploadHandler={async (file: File) => {
                                            if (onTemporaryImageUploadHandler) {
                                                return onTemporaryImageUploadHandler(file);
                                            }
                                            return "";
                                        }}
                                        onQuillInitialize={() => { }}
                                        setTitleCallback={() => { }}
                                        onImageCompleteCounterHandler={() => { }}
                                    />
                                </VerticalSpacing>
                                <StickyBottomBar
                                    leftSideLabel={""}
                                    isSubmitting={updateSubsmitting}
                                    rightSidebuttonConfirmLabel={"Save changes"}
                                    onConfirmHandler={onAboutPageSave}
                                    onCancelHandler={() => { setIsEditing(false) }}
                                />
                            </> : <VerticalSpacing bottom={80}>
                                <ContentDropdownContainer isPrivateView={isPrivateView} setIsEditing={setIsEditing}>
                                    <V3ArticleView>
                                        {
                                            !!renderedContent.length
                                                ? <div dangerouslySetInnerHTML={{ __html: renderedContent }} suppressHydrationWarning />
                                                : <>
                                                    {
                                                        isPrivateView ? <FakeAboutPagePlaceholder>
                                                            <p>You can add text, images and videos in this area.</p>
                                                            <p>Click to edit!</p>
                                                        </FakeAboutPagePlaceholder> : <></>
                                                    }
                                                </>
                                        }
                                    </V3ArticleView>
                                </ContentDropdownContainer>
                            </VerticalSpacing>
                        }
                    </>
                </>
            }
        </V4AboutPageWrapper >
    </>
}