import { ProfileView } from "authory-api-types/dist/enums";

export const DEFAULT_SEARCH_PARAMS = {
    COLLECTION: "_all",
    TEXT: "",
    FROM: null,
    TO: null,
    SHARESFROM: null,
    SHARESTO: null,
    SOURCE: "",
    VISIBILITY: "",
    VIEW: ProfileView.TwoColumnThumbnail,
    PAGE: "1",
    TYPE: "",
    SORT: "date_DESC",
    SUBACCOUNT: "",
    ABOUTPAGE: undefined,
}