import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { SPSubInputWrapper } from "./V3SubscrivePopup.styles";
import { VerticalSpacing } from "../VerticalSpacing";
import { V3Body } from "../Typography";
import { V3CaptionGrey35 } from "../ReusableStyledComponents/V3CaptionGrey35";
import { V3TextInput } from "../TextInput/V3TextInput";
import { V3Loader } from "../Loader";
import { V3Button } from "../Button";
import { V3BodyGrey35 } from "../ReusableStyledComponents/V3BodyGrey35";
import { V3Link } from "../ReusableStyledComponents/V3Link";
import { V3ModalBase } from "../V3ModalBase";

type V3SubscribePopupProps = {
    name: string,
    firstName: string,
    slug: string
    onSubscribeSumit: (email: string) => Promise<void>,
    disabled: boolean,
    hasPrivacyPolicy?: boolean,
    onCloseHandler: (open: boolean) => void,
}

export const V3SubscribePopup = ({
    name,
    hasPrivacyPolicy,
    firstName,
    onSubscribeSumit,
    slug,
    disabled,
    onCloseHandler
}: V3SubscribePopupProps) => {
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (data) => {
            setSubmitting(true);
            await onSubscribeSumit(data.email);
            setSubmitting(false);
            setSubmitted(true);
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .ensure()
                .email("Enter a valid email address")
                .required("Please enter an email address"),
        })
    });

    if (disabled) return <></>;

    return <V3ModalBase
        isOpen
        onClose={() => {
            onCloseHandler(false);
            formik.resetForm();
        }}
        title={`Subscribe to ${name}`}
    >
        {
            !submitted ?
                <form onSubmit={formik.handleSubmit}>
                    <VerticalSpacing bottom={24}>
                        <V3BodyGrey35>Be kept in the loop and subscribe to receive {name}'s latest updates.</V3BodyGrey35>
                    </VerticalSpacing>
                    <VerticalSpacing bottom={16}>
                        <SPSubInputWrapper>
                            <div>
                                <V3TextInput
                                    error={formik.errors.hasOwnProperty("email") && formik.touched.hasOwnProperty("email") ? formik.errors.email : undefined}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email..."
                                />
                            </div>
                            <div>
                                {!submitting ? <V3Button
                                    text="Subscribe"
                                    type="submit"
                                    minWidth={144}
                                /> : <V3Loader />}
                            </div>
                        </SPSubInputWrapper>
                    </VerticalSpacing>
                    <V3BodyGrey35>You can unsubscribe anytime. You can also follow {firstName} via {" "}<V3Link href={`/${slug}/rss`} as="a" target="_blank"><strong>RSS</strong></V3Link>.</V3BodyGrey35>
                    {
                        hasPrivacyPolicy && <VerticalSpacing top={4}>
                            <V3CaptionGrey35>
                                <V3Link href={`/${slug}/PrivacyPolicy`} as="a" target="_blank"><strong>Privacy Policy</strong></V3Link>.
                            </V3CaptionGrey35>
                        </VerticalSpacing>
                    }
                </form>
                : <>
                    <VerticalSpacing bottom={8}>
                        <img src="/authory_static_files/images/info-blue.svg" width="28" height="28" />
                    </VerticalSpacing>
                    <div style={{ wordBreak: "break-word" }}>
                        <V3Body>Almost there! Please go to your inbox now and click the confirmation link that we've just sent to <strong>{formik.values.email}</strong>.</V3Body>
                    </div>
                </>
        }
    </V3ModalBase>
}