import styled from "styled-components";
import { media } from "../../theme/theme";

const MBBRR = 480;

export const SPSubInputWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ${media.custom(MBBRR)} {
        flex-wrap: nowrap;
    }

    > div {
        &:first-child {
            width: 100%;
            flex-grow: 1;
            margin-bottom: 16px;

            ${media.custom(MBBRR)} {
                margin-right: 16px;
                margin-bottom: 0;
            }
        }
    }
`;