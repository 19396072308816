import { UserResponse } from "authory-api-types/dist/types";
import { DEFAULT_SEARCH_PARAMS } from "../types/profile_search_params";
import { AuthenticatedUser } from "../types/user";
import { getAllContentCollection } from "./getAllContentCollection";
import { getV3ProfileCollections } from "./getV3ProfileCollections";

export const processQueryArgs = (queryArgs: any, user: AuthenticatedUser | UserResponse) => {
    const { profileCollections, firstCollection } = getV3ProfileCollections(user?.collections);

    const queryCollection = !!queryArgs.collection ? user?.collections.find(col => col.canonicalSlug === queryArgs.collection.split("-").pop()) : undefined;


    const searchArgs = {
        ...queryArgs,
        collection: queryCollection ? queryCollection.canonicalSlug : firstCollection?.canonicalSlug
    }

    // Temporary fix for users who shared profile with the _all content collection, we redirect them to the all content collection if exists
    if ((searchArgs.collection === DEFAULT_SEARCH_PARAMS.COLLECTION || searchArgs.collection === "__all") && user) {
        const allContentCollection = getAllContentCollection(user?.collections);

        if (allContentCollection) searchArgs.collection = allContentCollection?.canonicalSlug;
        else searchArgs.collection = firstCollection?.canonicalSlug;
    }

    return { searchArgs, profileCollections, firstCollection };
}